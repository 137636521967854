.E-container {
  border: 0.1px solid rgb(255, 255, 255);
}
.E-container .modal {
  width: 33%;
  top: 25%;
  right: 30%;
  position: fixed;
  z-index: 8;
}
.E-container .modal .modal-content span {
  padding: 0 10px;
  font-size: 2.5rem;
  position: absolute;
  z-index: 10;
  right: 0.1px;
  cursor: pointer;
}
.E-container .modal .modal-content span:hover {
  background-color: rgba(255, 0, 0, 0.5);
  color: white;
}
.E-container .modal .modal-content img {
  width: 100%;
}
.E-container .E-mainPart {
  display: flex;
  justify-content: center;
}
.E-container .E-mainPart table,
.E-container .E-mainPart tr,
.E-container .E-mainPart td,
.E-container .E-mainPart th {
  border: 2px solid black;
  font-size: 1.5rem;
  border-collapse: collapse;
}
.E-container .E-mainPart table .imgContainer,
.E-container .E-mainPart tr .imgContainer,
.E-container .E-mainPart td .imgContainer,
.E-container .E-mainPart th .imgContainer {
  width: 8%;
}
.E-container .E-mainPart table .imgContainer img,
.E-container .E-mainPart tr .imgContainer img,
.E-container .E-mainPart td .imgContainer img,
.E-container .E-mainPart th .imgContainer img {
  width: 100%;
  position: relative;
  cursor: pointer;
}
.E-container .E-mainPart table {
  width: 90%;
  margin-bottom: 3rem;
}
.E-container .E-mainPart table thead {
  background-color: rgba(9, 147, 43, 0.5);
}
.E-container .E-mainPart table thead th {
  font-size: 1.75rem;
  padding: 0.5rem;
}
.E-container .E-mainPart table tbody .td-name,
.E-container .E-mainPart table tbody .td-desc,
.E-container .E-mainPart table tbody .td-rent {
  padding: 0.75rem;
  line-height: 35px;
}
.E-container .E-mainPart table tbody .td-name {
  width: 15%;
  text-align: center;
  font-size: 2rem;
}
.E-container .E-mainPart table tbody .td-desc {
  width: 30%;
}
.E-container .E-mainPart table tbody .td-rent {
  width: 15%;
  text-align: center;
}
.E-container .E-mainPart table tbody .RWD-table {
  display: none;
}

@media screen and (max-width: 1200px) {
  div.E-container .modal {
    width: 50%;
    right: 25%;
  }
}
@media screen and (max-width: 570px) {
  div.E-container .title-container {
    margin: 1.25rem;
  }
  div.E-container .title-word {
    font-size: 2.5rem;
  }
  div.E-container .title-description {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 2rem;
  }
  div.E-container .modal {
    width: 70%;
    top: 35%;
    right: 15%;
  }
  div.E-container div.E-mainPart table,
  div.E-container div.E-mainPart tr,
  div.E-container div.E-mainPart td,
  div.E-container div.E-mainPart th {
    font-size: 1rem;
  }
  div.E-container div.E-mainPart table .imgContainer,
  div.E-container div.E-mainPart tr .imgContainer,
  div.E-container div.E-mainPart td .imgContainer,
  div.E-container div.E-mainPart th .imgContainer {
    width: 15%;
  }
  div.E-container div.E-mainPart table {
    width: 100%;
  }
  div.E-container div.E-mainPart table th {
    font-size: 1.25rem;
  }
  div.E-container div.E-mainPart table tbody .td-name,
  div.E-container div.E-mainPart table tbody .td-desc,
  div.E-container div.E-mainPart table tbody .td-rent {
    padding: 0.75rem;
    line-height: 35px;
  }
  div.E-container div.E-mainPart table tbody .td-name {
    width: 60%;
    font-size: 1.5rem;
  }
  div.E-container div.E-mainPart .td-rent,
  div.E-container div.E-mainPart .td-desc {
    display: none;
  }
  div.E-container div.E-mainPart .RWD-table {
    display: table-cell;
  }
  div.E-container div.E-mainPart .RWD-title {
    background-color: rgba(9, 147, 43, 0.5);
    font-size: 1.25rem;
    padding: 0.5rem;
  }
  div.E-container div.E-mainPart .RWD-desc-title {
    background-color: rgba(241, 186, 35, 0.5);
    font-size: 1.25rem;
  }
  div.E-container div.E-mainPart .RWD-content {
    padding: 0.5rem;
    line-height: 30px;
  }
}/*# sourceMappingURL=style.css.map */