* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Noto Sans TC", sans-serif;
}

nav {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: rgb(250, 235, 215);
  padding: 0.5rem;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.4);
}
nav .imgContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
nav .imgContainer div {
  width: 100px;
  height: 100px;
}
nav .imgContainer div img {
  width: 100%;
}
nav .imgContainer p {
  font-size: 1.75rem;
  margin-left: 1rem;
}
nav i {
  cursor: pointer;
  opacity: 0;
  font-size: 3rem;
  position: absolute;
  z-index: -3;
  top: 35px;
  right: 8px;
  color: rgba(197, 169, 131, 0.8);
}
nav .nav-ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style-type: none;
}
nav .nav-ul li {
  font-size: 1.25rem;
  margin: 0.5rem 1.25rem;
}
nav .nav-ul li a {
  text-decoration: none;
  color: black;
  padding-bottom: 1px;
  transition: all ease 0.5s;
}
nav .nav-ul li a:hover {
  color: rgb(6, 178, 236);
  border-bottom: 3px solid rgb(6, 178, 236);
}
nav .nav-ul .BC {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
nav .nav-ul .BC:hover > .BC-tab {
  display: block;
  animation: navTab 0.5s ease forwards;
}
nav .nav-ul .BC .BC-tab {
  list-style-type: none;
  position: absolute;
  top: -50rem;
  display: none;
  text-align: center;
  background-color: rgba(250, 235, 215, 0.9);
  border: 3px solid rgba(255, 255, 255, 0.306);
  border-top: none;
}
nav .nav-ul .EF {
  cursor: pointer;
  display: flex;
  justify-content: center;
}
nav .nav-ul .EF:hover > .EF-tab {
  display: block;
  animation: navTab 0.5s ease forwards;
}
nav .nav-ul .EF .EF-tab {
  list-style-type: none;
  position: absolute;
  display: none;
  text-align: center;
  background-color: rgba(250, 235, 215, 0.9);
  border: 3px solid rgba(255, 255, 255, 0.306);
  border-top: none;
}

.nav-ul-RWD {
  overflow-y: auto;
  background-color: rgba(237, 192, 69, 0.9);
  position: fixed;
  left: -100%;
  z-index: 30;
  width: 45%;
  transition: all 0.5s;
  height: 100%;
}
.nav-ul-RWD li {
  font-size: 2rem;
  padding: 32px;
  border-bottom: 3px solid rgb(128, 128, 128);
  position: relative;
}
.nav-ul-RWD li i {
  opacity: 0.5;
  position: absolute;
  top: 38px;
  right: 15px;
  transition: all 0.3s;
}
.nav-ul-RWD li a {
  text-decoration: none;
  color: black;
  padding-bottom: 1px;
  transition: all ease 0.5s;
}
.nav-ul-RWD li a:hover {
  color: rgb(6, 178, 236);
}
.nav-ul-RWD ul.BC-tab,
.nav-ul-RWD ul.EF-tab {
  background-color: rgb(240, 230, 140);
  list-style-type: none;
}
.nav-ul-RWD ul.BC-tab li,
.nav-ul-RWD ul.EF-tab li {
  border: none;
  position: relative;
  left: 20px;
}

.footer {
  background-color: rgba(250, 235, 215, 0.475);
  border-top: rgba(250, 235, 215, 0.2) 2px solid;
}
.footer .contactInfo .main-info {
  font-size: 1.25rem;
  margin: 1rem 1rem 0.5rem 1.5rem;
}
.footer .contactInfo .mail-info,
.footer .contactInfo .phone-info {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.25rem;
  margin: 0.5rem 1rem 0.5rem 1.5rem;
}
.footer .contactInfo .mail-info i,
.footer .contactInfo .phone-info i {
  margin-top: 5px;
}
.footer .contactInfo .mail-info p,
.footer .contactInfo .phone-info p {
  padding-left: 10px;
}
.footer .text-info {
  font-size: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.footer .imageContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  scroll-behavior: smooth;
}
.footer .imageContainer div {
  width: 50px;
  height: 50px;
  margin: 0.5rem;
}
.footer .imageContainer div img {
  width: 100%;
}

.slide {
  height: 80vh;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all ease 0.5s;
}
.slide button {
  cursor: pointer;
  border: none;
  margin: 1.5rem;
  width: 50px;
  height: 70px;
  font-size: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(248, 235, 3, 0.925);
  z-index: 15;
}
.slide img {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 80vh;
}

.QA-section {
  margin: 3rem 0;
}
.QA-section .whatIs1,
.QA-section .whatIs2 {
  display: flex;
  justify-content: space-evenly;
  padding: 0 3rem;
}
.QA-section .whatIs1 .whatIs1-que,
.QA-section .whatIs1 .whatIs1-ans,
.QA-section .whatIs1 .whatIs2-que,
.QA-section .whatIs1 .whatIs2-ans,
.QA-section .whatIs2 .whatIs1-que,
.QA-section .whatIs2 .whatIs1-ans,
.QA-section .whatIs2 .whatIs2-que,
.QA-section .whatIs2 .whatIs2-ans {
  border-radius: 20px;
  font-weight: bold;
  color: white;
  width: 30rem;
  height: 20rem;
  align-items: center;
  justify-content: center;
  display: flex;
  font-size: 3rem;
  margin: 2rem;
}
.QA-section .whatIs1 .whatIs1-que,
.QA-section .whatIs1 .whatIs2-que,
.QA-section .whatIs2 .whatIs1-que,
.QA-section .whatIs2 .whatIs2-que {
  background: -webkit-radial-gradient(rgba(255, 180, 60, 0.8), rgb(64, 225, 225));
}
.QA-section .whatIs1 .whatIs1-ans,
.QA-section .whatIs1 .whatIs2-ans,
.QA-section .whatIs2 .whatIs1-ans,
.QA-section .whatIs2 .whatIs2-ans {
  cursor: pointer;
  background-color: lightsalmon;
}
.QA-section .whatIs1 .whatIs1-ans:hover,
.QA-section .whatIs1 .whatIs2-ans:hover,
.QA-section .whatIs2 .whatIs1-ans:hover,
.QA-section .whatIs2 .whatIs2-ans:hover {
  background: -webkit-radial-gradient(circle, rgba(228, 95, 228, 0.7), lightsalmon);
}
.QA-section .whatIs1 .whatIs1-ans:hover .ans-filter,
.QA-section .whatIs1 .whatIs2-ans:hover .ans-filter,
.QA-section .whatIs2 .whatIs1-ans:hover .ans-filter,
.QA-section .whatIs2 .whatIs2-ans:hover .ans-filter {
  transition: all 1s;
  z-index: 3;
}
.QA-section .whatIs1 .whatIs1-ans:hover .re-ans-filter,
.QA-section .whatIs1 .whatIs2-ans:hover .re-ans-filter,
.QA-section .whatIs2 .whatIs1-ans:hover .re-ans-filter,
.QA-section .whatIs2 .whatIs2-ans:hover .re-ans-filter {
  z-index: -3;
}
.QA-section .whatIs1 .whatIs1-ans .re-ans-filter,
.QA-section .whatIs1 .whatIs2-ans .re-ans-filter,
.QA-section .whatIs2 .whatIs1-ans .re-ans-filter,
.QA-section .whatIs2 .whatIs2-ans .re-ans-filter {
  position: absolute;
}
.QA-section .whatIs1 .whatIs1-ans .ans-filter,
.QA-section .whatIs1 .whatIs2-ans .ans-filter,
.QA-section .whatIs2 .whatIs1-ans .ans-filter,
.QA-section .whatIs2 .whatIs2-ans .ans-filter {
  position: relative;
  z-index: -3;
  font-size: 2rem;
  padding: 0.8rem;
}
.QA-section .whatIs1 i,
.QA-section .whatIs2 i {
  color: rgb(101, 98, 98);
  font-size: 3rem;
  display: flex;
  align-items: center;
}

.four-service {
  background-color: rgba(77, 224, 243, 0.3);
  box-shadow: 10px 5px 10px 3px rgba(0, 0, 0, 0.4);
  overflow-x: hidden;
}
.four-service h1 {
  font-size: 3rem;
  margin: 2.5rem;
}
.four-service div,
.four-service p {
  display: flex;
  margin: 2rem 3rem;
  width: 70vw;
  transition: transform ease 0.8s;
  transform: translateX(-200%);
}
.four-service div i,
.four-service p i {
  display: flex;
  align-items: center;
  font-size: 2rem;
}
.four-service div h2,
.four-service p h2 {
  font-weight: normal;
  font-size: 2rem;
  margin-left: 1rem;
}
.four-service :nth-of-type(even) {
  transform: translateX(200%);
}
.four-service p {
  font-size: 1.5rem;
  margin-left: 6.5rem;
  line-height: 3.5rem;
}
.four-service .show {
  transform: translateX(15%);
}

.identity-partial {
  overflow: hidden;
  height: 100vh;
}
.identity-partial img {
  width: 100%;
  height: 100vh;
  opacity: 0.5;
  z-index: -5;
  position: absolute;
}
.identity-partial div.filter {
  width: 100%;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.6);
  position: absolute;
  z-index: -3;
}
.identity-partial h1 {
  display: flex;
  justify-content: center;
  padding-top: 3rem;
  font-size: 3rem;
  color: rgb(255, 255, 255);
  transform-origin: bottom;
  animation: swingEffect 6s infinite alternate 0.5s ease-in-out;
}
.identity-partial .identity-mainPartial div.identity-mini {
  display: flex;
  justify-content: space-around;
  position: relative;
}
.identity-partial .identity-mainPartial div.identity-mini .identity-mini-card {
  height: 12rem;
  width: 13rem;
  font-size: 1.5rem;
  text-align: center;
  background-color: rgba(255, 200, 0, 0.7);
  box-shadow: 10px 5px 10px 3px rgba(0, 0, 0, 0.4);
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 1s;
}
.identity-partial .identity-mainPartial div.identity-mini .active {
  top: 1rem;
}
.identity-partial .identity-mainPartial div.identity-mini .faded {
  top: 15rem;
}
.identity-partial .identity-mainPartial div.identity {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: space-around;
}
.identity-partial .identity-mainPartial div.identity .identity-card {
  box-shadow: 10px 5px 10px 3px rgba(0, 0, 0, 0.4);
  text-align: center;
  line-height: 18rem;
  background-color: rgb(205, 133, 63);
  height: 18rem;
  width: 15rem;
  border-radius: 10px 10px 100px 100px;
}
.identity-partial .identity-mainPartial div.identity .identity-card p {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  letter-spacing: 8px;
}

.apply {
  background-color: rgba(110, 255, 212, 0.3);
  box-shadow: 5px -5px 5px 5px rgba(0, 0, 0, 0.2);
}
.apply h1 {
  text-align: center;
  font-size: 3rem;
  padding: 2rem;
}
.apply div.flowChart {
  padding-bottom: 2rem;
  transform: translateY(120%);
  display: flex;
  justify-content: center;
  transition: all 0.3s ease;
}
.apply div.flowChart div.chart-elm {
  width: 15vw;
}
.apply div.flowChart div.chart-elm p {
  font-size: 1.5rem;
}
.apply div.flowChart div.chart-elm p strong {
  color: red;
  font-size: 2.5rem;
}
.apply div.flowChart span {
  transform: translateY(40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.apply div.flowChart span p {
  line-height: 2rem;
  font-size: 1.25rem;
  padding-top: 3rem;
}
.apply div.flowChart span i {
  padding-top: 3rem;
  color: rgb(255, 0, 0);
  font-size: 3.5rem;
}
.apply p {
  text-align: center;
  font-size: 1.25rem;
  padding: 5px 0;
}
.apply img {
  width: 100%;
}
.apply .phoneImg {
  width: 80%;
  transform: translateY(20px);
  margin-left: 25px;
}

#ToTop {
  border: 1px solid black;
  width: 35px;
  height: 45px;
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  background-color: rgb(219, 151, 34);
}
#ToTop a {
  text-decoration: none;
}
#ToTop i {
  color: white;
  font-size: 1.8rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fade {
  display: none;
}

@keyframes navTab {
  from {
    top: 140px;
  }
  to {
    top: 142px;
  }
}
@keyframes swingEffect {
  from {
    transform: skewX(10deg);
  }
  to {
    transform: skewX(-15deg);
  }
}
@media screen and (max-width: 1200px) {
  nav i {
    opacity: 1;
    z-index: 3;
  }
  nav .nav-ul {
    display: none;
  }
  .nav-ul-RWD {
    overflow-y: auto;
  }
  .nav-ul-RWD li {
    font-size: 1.75rem;
    padding: 32px;
    position: relative;
  }
  .nav-ul-RWD li i {
    opacity: 0.5;
    position: absolute;
    top: 38px;
    right: 15px;
    transition: all 0.3s;
  }
  .nav-ul-RWD li a {
    text-decoration: none;
    color: black;
    padding-bottom: 1px;
    transition: all ease 0.5s;
  }
  .nav-ul-RWD li a:hover {
    color: rgb(6, 178, 236);
  }
  .nav-ul-RWD ul.BC-tab,
  .nav-ul-RWD ul.EF-tab {
    background-color: rgb(240, 230, 140);
    list-style-type: none;
  }
  .nav-ul-RWD ul.BC-tab li,
  .nav-ul-RWD ul.EF-tab li {
    border: none;
    position: relative;
    left: 20px;
  }
  .QA-section .whatIs1 .active,
  .QA-section .whatIs2 .active {
    background: -webkit-radial-gradient(circle, rgba(228, 95, 228, 0.9), lightsalmon);
  }
  .QA-section .whatIs1 .active .ans-filter,
  .QA-section .whatIs2 .active .ans-filter {
    transition: all 1s;
    z-index: 3;
  }
  .QA-section .whatIs1 .active .re-ans-filter,
  .QA-section .whatIs2 .active .re-ans-filter {
    z-index: -3;
  }
  .identity-partial {
    height: 100vh;
  }
  .identity-partial img,
  .identity-partial div.filter {
    height: 100vh;
  }
  .identity-partial div.identity-mini .identity-mini-card {
    width: 12rem;
  }
  .identity-partial div.identity-mainPartial {
    position: relative;
    top: 5rem;
  }
}
@media screen and (max-width: 570px) {
  nav i {
    font-size: 2rem;
    top: 40px;
  }
  .nav-ul-RWD {
    overflow-y: auto;
    width: 100%;
  }
  .nav-ul-RWD li {
    font-size: 1.5rem;
    padding: 16px;
  }
  .nav-ul-RWD li i {
    top: 22px;
  }
  .slide {
    height: 30vh;
  }
  .slide button {
    font-size: 2rem;
    width: 30px;
    height: 50px;
  }
  .slide img {
    height: 30vh;
  }
  .QA-section {
    margin: 1rem 0;
  }
  .QA-section .whatIs1,
  .QA-section .whatIs2 {
    padding: 0 0.75rem;
  }
  .QA-section .whatIs1 .whatIs1-que,
  .QA-section .whatIs1 .whatIs1-ans,
  .QA-section .whatIs1 .whatIs2-que,
  .QA-section .whatIs1 .whatIs2-ans,
  .QA-section .whatIs2 .whatIs1-que,
  .QA-section .whatIs2 .whatIs1-ans,
  .QA-section .whatIs2 .whatIs2-que,
  .QA-section .whatIs2 .whatIs2-ans {
    width: 30rem;
    height: 10rem;
    font-size: 1rem;
    margin: 0.75rem;
  }
  .QA-section .whatIs1 .whatIs1-ans .ans-filter,
  .QA-section .whatIs1 .whatIs2-ans .ans-filter,
  .QA-section .whatIs2 .whatIs1-ans .ans-filter,
  .QA-section .whatIs2 .whatIs2-ans .ans-filter {
    z-index: -3;
    font-size: 0.75rem;
    padding: 1rem;
  }
  .QA-section .whatIs1 .active,
  .QA-section .whatIs2 .active {
    background: -webkit-radial-gradient(circle, rgba(228, 95, 228, 0.9), lightsalmon);
  }
  .QA-section .whatIs1 .active .ans-filter,
  .QA-section .whatIs2 .active .ans-filter {
    transition: all 1s;
    z-index: 3;
  }
  .QA-section .whatIs1 .active .re-ans-filter,
  .QA-section .whatIs2 .active .re-ans-filter {
    z-index: -3;
  }
  .QA-section .whatIs1 i,
  .QA-section .whatIs2 i {
    font-size: 1.5rem;
  }
  .four-service div,
  .four-service p {
    margin: 1.25rem 0rem;
    width: 80vw;
  }
  .four-service div i,
  .four-service p i {
    font-size: 1.75rem;
  }
  .four-service div h2,
  .four-service p h2 {
    font-size: 1.75rem;
  }
  .four-service p {
    font-size: 1.25rem;
    margin-left: 0rem;
    line-height: 2.5rem;
  }
  .identity-partial {
    height: 110vh;
  }
  .identity-partial img {
    height: 110vh;
  }
  .identity-partial h1 {
    padding-top: 5rem;
    font-size: 2.75rem;
  }
  .identity-partial div.filter {
    width: 100%;
    height: 110vh;
  }
  .identity-partial .identity-mainPartial div.identity-mini {
    display: flex;
    flex-wrap: wrap;
    top: -10rem;
  }
  .identity-partial .identity-mainPartial div.identity-mini .identity-mini-card {
    box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.4);
    font-size: 1rem;
    height: 7.5rem;
    width: 36%;
    margin-bottom: 15rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  .identity-partial .identity-mainPartial div.identity-mini .active {
    top: 5.5rem;
  }
  .identity-partial .identity-mainPartial div.identity-mini .faded {
    top: 15rem;
  }
  .identity-partial .identity-mainPartial div.identity {
    width: 100%;
    position: absolute;
    top: 3rem;
    z-index: 5;
    display: flex;
    flex-wrap: wrap;
  }
  .identity-partial .identity-mainPartial div.identity .identity-card {
    width: 40%;
    height: 14rem;
    line-height: 14rem;
    margin-bottom: 8rem;
  }
  .identity-partial .identity-mainPartial div.identity .identity-card p {
    font-size: 1.5rem;
    letter-spacing: 3px;
  }
  .apply h1 {
    font-size: 2rem;
    padding: 1rem;
  }
  .apply div.flowChart div.chart-elm {
    width: 30vw;
  }
  .apply div.flowChart div.chart-elm p {
    font-size: 1rem;
  }
  .apply div.flowChart div.chart-elm p strong {
    font-size: 1rem;
  }
  .apply div.flowChart span p {
    font-size: 0.5rem;
    width: 200%;
    line-height: 0.75rem;
    padding-top: 1rem;
  }
  .apply div.flowChart span i {
    padding-top: 0;
    font-size: 1.5rem;
  }
  .apply p {
    text-align: center;
    font-size: 0.75rem;
    padding: 5px 0;
  }
  .apply .phoneImg {
    width: 80%;
    transform: translateY(0);
    margin-left: 8px;
  }
  .footer .contactInfo .main-info {
    font-size: 1rem;
    margin: 1rem 1rem 0.5rem 0.5rem;
  }
  .footer .contactInfo .mail-info,
  .footer .contactInfo .phone-info {
    font-size: 1rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
  }
  .footer .contactInfo .mail-info p,
  .footer .contactInfo .phone-info p {
    padding-left: 5px;
  }
  .footer .text-info {
    font-size: 0.75rem;
  }
  .footer .imageContainer div {
    width: 30px;
    height: 30px;
    margin: 0.5rem;
  }
}/*# sourceMappingURL=style.css.map */