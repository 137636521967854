.D-container {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(128, 128, 128, 0.1);
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
  .D-mainPart {
    display: flex;
    justify-content: center;
    align-items: center;
    .imgContainer {
      margin: 2rem;
      width: 50%;
      img {
        width: 100%;
      }
    }
    .show {
      animation: remove ease 0.6s forwards;
    }
    .D-part-Description {
      p {
        font-weight: bold;
        font-size: 2.5rem;
        padding: 1.5rem;
        color: brown;
      }
    }
  }
}

//動畫秘訣 animation: remove ease 5s forwards;
@keyframes remove {
  0% {
    transform: translateX(-400%);
  }
  66% {
    transform: translateX(100%);
    transform: skewX(-20deg);
  }
  100% {
    transform: skewX(1deg);
  }
}

@media screen and (max-width: 1100px) {
  div.D-container {
    div.D-mainPart {
      .D-part-Description {
        p {
          font-size: 2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .D-container {
    .title-container {
      margin:1.5rem;
    }
    .title-word {
      font-size: 2.5rem;
    }
    .title-description {
      font-size: 1.5rem;
      padding:10px;
      line-height: 2rem;
    }

    .D-mainPart {
      flex-direction: column;
      .imgContainer {
        margin: 1rem;
        width: 70%;
        img {
          width: 100%;
        }
      }
      .D-part-Description {
        p {
          font-size: rem;
        }
      }
    }
  }
}
