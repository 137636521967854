.img-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.img-container .img-div {
  position: relative;
  bottom: 6px;
  margin-bottom: 3rem;
}

@media screen and (max-width: 570px) {
  div.F-container .title-container {
    margin: 2rem;
  }
  div.F-container .title-word {
    font-size: 2.5rem;
  }
  div.F-container .title-description {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 2rem;
  }
  div.F-container .img-container {
    display: none;
  }
}/*# sourceMappingURL=style.css.map */