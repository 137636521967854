.E-container {
  border: 0.1px solid rgb(255, 255, 255);
  .modal {
    width: 33%;
    top: 25%;
    right: 30%;
    position: fixed;
    z-index: 8;
    .modal-content {
      span {
        padding: 0 10px;
        font-size: 2.5rem;
        position: absolute;
        z-index: 10;
        right: 0.1px;
        cursor: pointer;
        &:hover {
          background-color: rgba(255, 0, 0, 0.5);
          color: white;
        }
      }
      img {
        width: 100%;
      }
    }
  }

  .E-mainPart {
    display: flex;
    justify-content: center;
    table,
    tr,
    td,
    th {
      border: 2px solid black;
      font-size: 1.5rem;
      border-collapse: collapse;
      .imgContainer {
        width: 8%;
        img {
          width: 100%;
          position: relative;
          cursor: pointer;
        }
      }
    }

    table {
      width: 90%;
      margin-bottom: 3rem;
      thead {
        background-color: rgba(9, 147, 43, 0.5);
        th {
          font-size: 1.75rem;
          padding: 0.5rem;
        }
      }
      tbody {
        .td-name,
        .td-desc,
        .td-rent {
          padding: 0.75rem;
          line-height: 35px;
        }

        .td-name {
          width: 15%;
          text-align: center;
          font-size: 2rem;
        }
        .td-desc {
          width: 30%;
        }
        .td-rent {
          width: 15%;
          text-align: center;
        }
        .RWD-table {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  div.E-container {
    .modal {
      width: 50%;
      right: 25%;
    }
  }
}

@media screen and (max-width: 570px) {
  div.E-container {
    .title-container {
      margin: 1.25rem;
    }
    .title-word {
      font-size: 2.5rem;
    }
    .title-description {
      font-size: 1.5rem;
      padding: 10px;
      line-height: 2rem;
    }

    .modal {
      width: 70%;
      top: 35%;
      right: 15%;
    }

    div.E-mainPart {
      table,
      tr,
      td,
      th {
        font-size: 1rem;
        .imgContainer {
          width: 15%;
        }
      }

      table {
        width: 100%;
        th {
          font-size: 1.25rem;
        }

        tbody {
          .td-name,
          .td-desc,
          .td-rent {
            padding: 0.75rem;
            line-height: 35px;
          }

          .td-name {
            width: 60%;
            font-size: 1.5rem;
          }
        }
      }

      .td-rent,
      .td-desc {
        display: none;
      }
      .RWD-table {
        display: table-cell;
      }
      .RWD-title {
        background-color: rgba(9, 147, 43, 0.5);
        font-size: 1.25rem;
        padding: 0.5rem;
      }

      .RWD-desc-title {
        background-color: rgba(241, 186, 35, 0.5);
        font-size: 1.25rem;
      }
      .RWD-content {
        padding: 0.5rem;
        line-height: 30px;
      }
    }
  }
}
