* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Noto Sans TC", sans-serif;
}

h1 {
  text-decoration: none;
}

nav {
  position: sticky;
  z-index: 99;
  top: 0;
  background-color: rgba(250, 235, 215, 1);
  padding: 0.5rem;
  box-shadow: 5px 5px 5px rgb(0, 0, 0, 0.4);
  .imgContainer {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    div {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
      }
    }
    p {
      color: red;
      font-size: 1.75rem;
      margin-left: 1rem;
    }
  }
  i {
    cursor: pointer;
    opacity: 0;
    font-size: 3rem;
    position: absolute;
    z-index: -3;
    top: 35px;
    right: 8px;
    color: rgb(197, 169, 131, 0.8);
  }

  .nav-ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    li {
      font-size: 1.25rem;
      margin: 0.5rem 1.25rem;
      a {
        text-decoration: none;
        color: black;
        padding-bottom: 1px;
        transition: all ease 0.5s;
        &:hover {
          color: rgb(6, 178, 236);
          border-bottom: 3px solid rgb(6, 178, 236);
        }
      }
    }
    .BC {
      cursor: pointer;
      display: flex;
      justify-content: center;
      &:hover > .BC-tab {
        display: block;
        animation: navTab 0.5s ease forwards;
      }
      .BC-tab {
        list-style-type: none;
        position: absolute;
        top: -50rem;
        display: none;
        text-align: center;
        background-color: rgba(250, 235, 215, 0.9);
        border: 3px solid rgba(255, 255, 255, 0.306);
        border-top: none;
      }
    }
    .EF {
      cursor: pointer;
      display: flex;
      justify-content: center;
      &:hover > .EF-tab {
        display: block;
        animation: navTab 0.5s ease forwards;
      }
      .EF-tab {
        list-style-type: none;
        position: absolute;
        display: none;
        text-align: center;
        background-color: rgba(250, 235, 215, 0.9);
        border: 3px solid rgba(255, 255, 255, 0.306);
        border-top: none;
      }
    }
  }
}
.nav-ul-RWD {
  overflow-y: auto;
  background-color: rgba(237, 192, 69, 0.9);
  position: fixed;
  left: -100%;
  z-index: 30;
  width: 45%;
  transition: all 0.5s;
  height: 100%;
  li {
    font-size: 2rem;
    padding: 32px;
    border-bottom: 3px solid rgba(128, 128, 128);
    position: relative;
    i {
      opacity: 0.5;
      position: absolute;
      top: 38px;
      right: 15px;
      transition: all 0.3s;
    }

    a {
      text-decoration: none;
      color: black;
      padding-bottom: 1px;
      transition: all ease 0.5s;
      &:hover {
        color: rgb(6, 178, 236);
      }
    }
  }
  ul.BC-tab,
  ul.EF-tab {
    background-color: rgba(240, 230, 140);
    list-style-type: none;
    li {
      border: none;
      position: relative;
      left: 20px;
    }
  }
}

//OK
.footer {
  background-color: rgba(250, 235, 215, 0.475);
  border-top: rgba(250, 235, 215, 0.2) 2px solid;
  .contactInfo {
    .main-info {
      font-size: 1.25rem;
      margin: 1rem 1rem 0.5rem 1.5rem;
    }
    .mail-info,
    .phone-info {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.25rem;
      margin: 0.5rem 1rem 0.5rem 1.5rem;
      i {
        margin-top: 5px;
      }
      p {
        padding-left: 10px;
      }
    }
  }

  .text-info {
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .imageContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    scroll-behavior: smooth;
    div {
      width: 50px;
      height: 50px;
      margin: 0.5rem;
      .footer-github {
        a {
        }
      }
      img {
        width: 100%;
      }
    }
  }
}

.slide {
  height: 80vh;
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all ease 0.5s;
  button {
    cursor: pointer;
    border: none;
    margin: 1.5rem;
    width: 50px;
    height: 70px;
    font-size: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(248, 235, 3, 0.925);
    z-index: 15;
  }
  img {
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 80vh;
    // animation: slideEffect 25s 5s liner infinite;
  }
}

.QA-section {
  margin: 3rem 0;
  .whatIs1,
  .whatIs2 {
    display: flex;
    justify-content: space-evenly;
    padding: 0 3rem;
    .whatIs1-que,
    .whatIs1-ans,
    .whatIs2-que,
    .whatIs2-ans {
      border-radius: 20px;
      font-weight: bold;
      color: white;
      width: 30rem;
      height: 20rem;
      align-items: center;
      justify-content: center;
      display: flex;
      font-size: 3rem;
      margin: 2rem;
    }

    .whatIs1-que,
    .whatIs2-que {
      background: -webkit-radial-gradient(
        rgb(255, 180, 60, 0.8),
        rgba(64, 225, 225)
      );
    }
    .whatIs1-ans,
    .whatIs2-ans {
      cursor: pointer;
      background-color: lightsalmon;
      &:hover {
        background: -webkit-radial-gradient(
          circle,
          rgba(228, 95, 228, 0.7),
          lightsalmon
        );
        .ans-filter {
          transition: all 1s;
          z-index: 3;
        }
        .re-ans-filter {
          z-index: -3;
        }
      }
      .re-ans-filter {
        position: absolute;
      }
      .ans-filter {
        position: relative;
        z-index: -3;
        font-size: 2rem;
        padding: 0.8rem;
      }
    }

    i {
      color: rgb(101, 98, 98);
      font-size: 3rem;
      display: flex;
      align-items: center;
    }
  }
}

.four-service {
  background-color: rgba(77, 224, 243, 0.3);
  box-shadow: 10px 5px 10px 3px rgb(0, 0, 0, 0.4);
  overflow-x: hidden;

  h1 {
    font-size: 3rem;
    margin: 2.5rem;
  }
  div,
  p {
    display: flex;
    margin: 2rem 3rem;
    width: 70vw;
    transition: transform ease 0.8s;
    transform: translateX(-200%);
    i {
      display: flex;
      align-items: center;
      font-size: 2rem;
    }
    h2 {
      font-weight: normal;
      font-size: 2rem;
      margin-left: 1rem;
    }
  }
  :nth-of-type(even) {
    transform: translateX(200%);
  }

  p {
    font-size: 1.5rem;
    margin-left: 6.5rem;
    line-height: 3.5rem;
  }
  .show {
    transform: translateX(15%);
  }
}

.identity-partial {
  overflow: hidden;
  height: 100vh;
  img {
    width: 100%;
    height: 100vh;
    opacity: 0.5;
    z-index: -5;
    position: absolute;
  }
  div.filter {
    width: 100%;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.6);
    position: absolute;
    z-index: -3;
  }
  h1 {
    display: flex;
    justify-content: center;
    padding-top: 3rem;
    font-size: 3rem;
    color: rgba(255, 255, 255);
    transform-origin: bottom;
    animation: swingEffect 6s infinite alternate 0.5s ease-in-out;
  }

  .identity-mainPartial {
    div.identity-mini {
      display: flex;
      justify-content: space-around;
      position: relative;
      .identity-mini-card {
        height: 12rem;
        width: 13rem;
        font-size: 1.5rem;
        text-align: center;
        background-color: rgb(255, 200, 0, 0.7);
        box-shadow: 10px 5px 10px 3px rgb(0, 0, 0, 0.4);
        padding: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 1;
        transition: all 1s;
      }
      .active {
        top: 1rem;
      }
      .faded {
        top: 15rem;
      }
    }

    div.identity {
      position: relative;
      z-index: 5;
      display: flex;
      justify-content: space-around;
      .identity-card {
        box-shadow: 10px 5px 10px 3px rgb(0, 0, 0, 0.4);
        text-align: center;
        line-height: 18rem;
        background-color: rgba(205, 133, 63);
        height: 18rem;
        width: 15rem;
        border-radius: 10px 10px 100px 100px;

        p {
          font-size: 2rem;
          font-weight: bold;
          color: white;
          letter-spacing: 8px;
        }
      }
    }
  }
}

.apply {
  background-color: rgba(110, 255, 212, 0.3);
  // background-color: rgba(236, 174, 93, 0.6);
  box-shadow: 5px -5px 5px 5px rgb(0, 0, 0, 0.2);

  h1 {
    text-align: center;
    font-size: 3rem;
    padding: 2rem;
  }
  div.flowChart {
    padding-bottom: 2rem;
    transform: translateY(120%);
    display: flex;
    justify-content: center;
    transition: all 0.3s ease;
    div.chart-elm {
      width: 15vw;
      p {
        font-size: 1.5rem;
        strong {
          color: red;
          font-size: 2.5rem;
        }
      }
    }
    span {
      transform: translateY(40px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      p {
        line-height: 2rem;
        font-size: 1.25rem;
        padding-top: 3rem;
      }
      i {
        padding-top: 3rem;
        color: rgba(255, 0, 0);
        font-size: 3.5rem;
      }
    }
  }

  p {
    text-align: center;
    font-size: 1.25rem;
    padding: 5px 0;
  }
  img {
    width: 100%;
  }
  .phoneImg {
    width: 80%;
    transform: translateY(20px);
    margin-left: 25px;
  }
}

#ToTop {
  border: 1px solid black;
  width: 35px;
  height: 45px;
  position: fixed;
  z-index: 10;
  right: 20px;
  bottom: 20px;
  background-color: rgb(219, 151, 34);
  a {
    text-decoration: none;
  }
  i {
    color: white;
    font-size: 1.8rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.fade {
  display: none;
}

@keyframes navTab {
  from {
    top: 140px;
  }
  to {
    top: 142px;
  }
}

@keyframes swingEffect {
  from {
    transform: skewX(10deg);
  }
  to {
    transform: skewX(-15deg);
  }
}

@media screen and (max-width: 1200px) {
  nav {
    i {
      opacity: 1;
      z-index: 3;
    }
    .nav-ul {
      display: none;
    }
  }

  .nav-ul-RWD {
    overflow-y: auto;
    // height: 100%;
    li {
      font-size: 1.75rem;
      padding: 32px;
      position: relative;
      i {
        opacity: 0.5;
        position: absolute;
        top: 38px;
        right: 15px;
        transition: all 0.3s;
      }

      a {
        text-decoration: none;
        color: black;
        padding-bottom: 1px;
        transition: all ease 0.5s;
        &:hover {
          color: rgb(6, 178, 236);
        }
      }
    }
    ul.BC-tab,
    ul.EF-tab {
      background-color: rgba(240, 230, 140);
      list-style-type: none;
      li {
        border: none;
        position: relative;
        left: 20px;
      }
    }
  }

  .QA-section {
    .whatIs1,
    .whatIs2 {
      .active {
        background: -webkit-radial-gradient(
          circle,
          rgba(228, 95, 228, 0.9),
          lightsalmon
        );
        .ans-filter {
          transition: all 1s;
          z-index: 3;
        }
        .re-ans-filter {
          z-index: -3;
        }
      }
    }
  }

  .identity-partial {
    height: 100vh;
    img,
    div.filter {
      height: 100vh;
    }

    div.identity-mini {
      .identity-mini-card {
        width: 12rem;
      }
    }
    div.identity-mainPartial {
      position: relative;
      top: 5rem;
    }
  }
}

@media screen and (max-width: 570px) {
  nav {
    i {
      font-size: 2rem;
      top: 40px;
    }
  }

  .nav-ul-RWD {
    overflow-y: auto;
    width: 100%;
    li {
      font-size: 1.5rem;
      padding: 16px;
      i {
        top: 22px;
      }
    }
  }

  .slide {
    height: 30vh;
    button {
      font-size: 2rem;
      width: 30px;
      height: 50px;
    }
    img {
      height: 30vh;
    }
  }

  .QA-section {
    margin: 1rem 0;
    .whatIs1,
    .whatIs2 {
      padding: 0 0.75rem;
      .whatIs1-que,
      .whatIs1-ans,
      .whatIs2-que,
      .whatIs2-ans {
        width: 30rem;
        height: 10rem;
        font-size: 1rem;
        margin: 0.75rem;
      }
      .whatIs1-ans,
      .whatIs2-ans {
        .ans-filter {
          z-index: -3;
          font-size: 0.75rem;
          padding: 1rem;
        }
      }
      .active {
        background: -webkit-radial-gradient(
          circle,
          rgba(228, 95, 228, 0.9),
          lightsalmon
        );
        .ans-filter {
          transition: all 1s;
          z-index: 3;
        }
        .re-ans-filter {
          z-index: -3;
        }
      }
      i {
        font-size: 1.5rem;
      }
    }
  }

  .four-service {
    div,
    p {
      margin: 1.25rem 0rem;
      width: 80vw;
      i {
        font-size: 1.75rem;
      }
      h2 {
        font-size: 1.75rem;
      }
    }

    p {
      font-size: 1.25rem;
      margin-left: 0rem;
      line-height: 2.5rem;
    }
  }

  .identity-partial {
    height: 110vh;
    img {
      // width: 100%;
      height: 110vh;
    }
    h1 {
      padding-top: 5rem;
      font-size: 2.75rem;
    }
    div.filter {
      width: 100%;
      height: 110vh;
    }

    .identity-mainPartial {
      div.identity-mini {
        display: flex;
        flex-wrap: wrap;
        top: -10rem;
        .identity-mini-card {
          box-shadow: 5px 5px 5px 3px rgb(0, 0, 0, 0.4);
          font-size: 1rem;
          height: 7.5rem;
          width: 36%;
          margin-bottom: 15rem;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        .active {
          top: 5.5rem;
        }
        .faded {
          top: 15rem;
        }
      }

      div.identity {
        width: 100%;
        position: absolute;
        top: 3rem;
        z-index: 5;
        display: flex;
        flex-wrap: wrap;
        .identity-card {
          width: 40%;
          height: 14rem;
          line-height: 14rem;
          margin-bottom: 8rem;
          p {
            font-size: 1.5rem;
            letter-spacing: 3px;
          }
        }
      }
    }
  }

  .apply {
    h1 {
      font-size: 2rem;
      padding: 1rem;
    }
    div.flowChart {
      div.chart-elm {
        width: 30vw;
        p {
          font-size: 1rem;
          strong {
            font-size: 1rem;
          }
        }
      }
      span {
        p {
          font-size: 0.5rem;
          width: 200%;
          line-height: 0.75rem;
          padding-top: 1rem;
        }
        i {
          padding-top: 0;
          font-size: 1.5rem;
        }
      }
    }

    p {
      text-align: center;
      font-size: 0.75rem;
      padding: 5px 0;
    }
    .phoneImg {
      width: 80%;
      transform: translateY(0);
      margin-left: 8px;
    }
  }

  .footer {
    .contactInfo {
      .main-info {
        font-size: 1rem;
        margin: 1rem 1rem 0.5rem 0.5rem;
      }
      .mail-info,
      .phone-info {
        font-size: 1rem;
        margin: 0.5rem 1rem 0.5rem 0.5rem;
        p {
          padding-left: 5px;
        }
      }
    }

    .text-info {
      font-size: 0.75rem;
    }

    .imageContainer {
      div {
        width: 30px;
        height: 30px;
        margin: 0.5rem;
      }
    }
  }
}
