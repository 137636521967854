.G-container main.G-mainPart {
  display: flex;
  justify-content: center;
}
.G-container main.G-mainPart div.imgContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 2rem 1rem 2rem;
}
.G-container main.G-mainPart div.imgContainer img {
  width: 80%;
  height: 50vh;
  border-radius: 50%;
}
.G-container main.G-mainPart div.imgContainer .G-desc {
  text-align: center;
  color: rgb(4, 108, 236);
  font-weight: bold;
  position: absolute;
  font-size: 2rem;
  padding-top: 5rem;
  width: 60%;
  border-radius: 50%;
}
.G-container main.G-mainPart div.imgContainer .ga-1 {
  padding-top: 6rem;
}
.G-container main.G-mainPart div.imgContainer p {
  position: relative;
  cursor: pointer;
  background-color: rgba(201, 225, 18, 0.5);
  border-radius: 20px;
  font-weight: bold;
  padding: 0.5rem;
  font-size: 1.75rem;
  margin-top: 1rem;
}
.G-container main.G-mainPart div.imgContainer span {
  font-weight: bold;
  color: lightsalmon;
  animation: flashEffect 1s ease-in-out 0s infinite alternate;
  position: relative;
  top: -4rem;
  left: 4rem;
}
@keyframes flashEffect {
  from {
    transform: scale(1) rotate(10deg);
  }
  to {
    transform: scale(1.8) rotate(10deg);
  }
}
.G-container div.homeCare {
  padding: 5rem 0 3rem 0;
  text-align: center;
}
.G-container div.homeCare button {
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  font-size: 1.5rem;
  border: none;
  border-radius: 15px;
  background-color: lightskyblue;
  transition: all ease 0.3s;
}
.G-container div.homeCare button:hover {
  background-color: rgb(39, 167, 246);
}
.G-container div.homeCare main .title-container {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.G-container div.homeCare main .title-container .title-word {
  width: 100%;
  border-top: 5px solid rgba(128, 128, 128, 0.5);
  border-bottom: 5px solid rgba(128, 128, 128, 0.5);
}
.G-container div.homeCare main .title-container .title-description {
  width: 90%;
}
.G-container div.homeCare main .homeCare-service {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.G-container div.homeCare main .homeCare-service div {
  font-size: 2.25rem;
  width: 30%;
  padding: 1.5rem;
  margin: 1rem;
  border: 3px solid rgba(124, 104, 238, 0.8);
  border-radius: 40px;
  transition: all ease 0.8s;
}
.G-container div.homeCare main .homeCare-service div:hover {
  background-color: rgba(124, 104, 238, 0.3);
  border: none;
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold;
}

@media screen and (max-width: 1200px) {
  .G-container main.G-mainPart div.imgContainer {
    margin: 1.5rem 2rem 1rem 2rem;
  }
  .G-container main.G-mainPart div.imgContainer img {
    width: 80%;
    height: 30vh;
  }
  .G-container main.G-mainPart div.imgContainer .G-desc {
    font-size: 1.5rem;
    padding-top: 3rem;
    width: 70%;
  }
  .G-container main.G-mainPart div.imgContainer .ga-1 {
    padding-top: 4rem;
  }
  .G-container main.G-mainPart div.imgContainer p {
    cursor: pointer;
    background-color: rgba(201, 225, 18, 0.5);
    border-radius: 20px;
    font-weight: bold;
    padding: 0.5rem;
    font-size: 1.75rem;
    margin-top: 1rem;
  }
  .G-container div.homeCare {
    padding: 3rem 0 3rem 0;
  }
  .G-container div.homeCare main .title-container .title-word {
    width: 100%;
    font-size: 2.5rem;
  }
  .G-container div.homeCare main .title-container .title-description {
    width: 100%;
    font-size: 1.25rem;
  }
  .G-container div.homeCare main .homeCare-service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .G-container div.homeCare main .homeCare-service div {
    font-size: 1.5rem;
    width: 40%;
    padding: 1.5rem;
    margin: 0.75rem;
  }
  .G-container div.homeCare main .homeCare-service div:hover {
    background-color: rgba(124, 104, 238, 0.3);
    border: none;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
  }
}
@media screen and (max-width: 950px) {
  .G-container main.G-mainPart {
    flex-direction: column;
  }
  .G-container main.G-mainPart div.imgContainer img {
    width: 50%;
    height: 50vh;
  }
  .G-container main.G-mainPart div.imgContainer .G-desc {
    font-size: 1.75rem;
    padding-top: 8rem;
    width: 40%;
  }
  .G-container main.G-mainPart div.imgContainer .ga-1 {
    padding-top: 10rem;
  }
}
@media screen and (max-width: 570px) {
  .G-container .title-container {
    margin: 2rem;
  }
  .G-container .title-word {
    font-size: 2.5rem;
  }
  .G-container .title-description {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 2rem;
  }
  .G-container main.G-mainPart {
    flex-direction: column;
  }
  .G-container main.G-mainPart div.imgContainer img {
    width: 80%;
    height: 35vh;
  }
  .G-container main.G-mainPart div.imgContainer .G-desc {
    font-size: 1.5rem;
    padding-top: 5rem;
    width: 60%;
  }
  .G-container main.G-mainPart div.imgContainer .ga-1 {
    padding-top: 6rem;
  }
  .G-container div.homeCare {
    padding: 3rem 0 3rem 0;
  }
  .G-container div.homeCare main .title-container .title-word {
    width: 100%;
    font-size: 2.5rem;
  }
  .G-container div.homeCare main .title-container .title-description {
    width: 100%;
    font-size: 1.25rem;
  }
  .G-container div.homeCare main .homeCare-service {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  .G-container div.homeCare main .homeCare-service div {
    font-size: 1.5rem;
    width: 40%;
    padding: 1.5rem;
    margin: 0.75rem;
  }
  .G-container div.homeCare main .homeCare-service div:hover {
    background-color: rgba(124, 104, 238, 0.3);
    border: none;
    color: rgba(0, 0, 0, 0.6);
    font-weight: bold;
  }
}/*# sourceMappingURL=style.css.map */