.D-container {
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: rgba(128, 128, 128, 0.1);
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.1);
}
.D-container .D-mainPart {
  display: flex;
  justify-content: center;
  align-items: center;
}
.D-container .D-mainPart .imgContainer {
  margin: 2rem;
  width: 50%;
}
.D-container .D-mainPart .imgContainer img {
  width: 100%;
}
.D-container .D-mainPart .show {
  animation: remove ease 0.6s forwards;
}
.D-container .D-mainPart .D-part-Description p {
  font-weight: bold;
  font-size: 2.5rem;
  padding: 1.5rem;
  color: brown;
}

@keyframes remove {
  0% {
    transform: translateX(-400%);
  }
  66% {
    transform: translateX(100%);
    transform: skewX(-20deg);
  }
  100% {
    transform: skewX(1deg);
  }
}
@media screen and (max-width: 1100px) {
  div.D-container div.D-mainPart .D-part-Description p {
    font-size: 2rem;
  }
}
@media screen and (max-width: 570px) {
  .D-container .title-container {
    margin: 1.5rem;
  }
  .D-container .title-word {
    font-size: 2.5rem;
  }
  .D-container .title-description {
    font-size: 1.5rem;
    padding: 10px;
    line-height: 2rem;
  }
  .D-container .D-mainPart {
    flex-direction: column;
  }
  .D-container .D-mainPart .imgContainer {
    margin: 1rem;
    width: 70%;
  }
  .D-container .D-mainPart .imgContainer img {
    width: 100%;
  }
  .D-container .D-mainPart .D-part-Description p {
    font-size: rem;
  }
}/*# sourceMappingURL=style.css.map */