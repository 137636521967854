.title-container {
  margin: 2.5rem;
  margin-bottom: 0;
}

.title-word {
  color: darkblue;
  font-size: 3rem;
}
.title-description {
  font-size: 1.5rem;
  padding: 15px;
  line-height: 2rem;
}
// 以上三個可做通用標籤

.odd {
  flex-direction: row;
  .code-part {
    position: relative;
    z-index: 5;
    left: 5rem;
    p {
      padding: 1rem;
      padding-right: 8rem;
    }
  }
  .execute-part {
    position: relative;
    z-index: 3;
    padding: 1.25rem;
    padding-left: 6rem;
  }
}

.even {
  flex-direction: row-reverse;
  .code-part {
    p {
      position: relative;
      z-index: 5;
      padding: 1rem;
      padding-left: 8rem;
    }
  }
  .execute-part {
    position: relative;
    z-index: 3;
    left: 5rem;
    padding: 1.25rem;
    padding-right: 6rem;
  }
}

div.C-container {
  overflow-x: hidden;
  background-color: rgba(128, 128, 128, 0.1);
  box-shadow: 5px 5px 10px 3px rgba(0, 0, 0, 0.2);
  main.C-mainPart {
    .C-div-part {
      display: flex;
      justify-content: center;
      margin: 5rem;
      .code-part {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32vw;
        p {
          width: 100%;
          font-size: 2rem;
          text-align: center;
          background-color: rgba(255, 240, 0, 0.3);
        }
      }
      .execute-part {
        color: white;
        line-height: 3rem;
        text-align: center;
        background-color: rgba(255, 149, 0, 0.8);
        width: 50vw;
        height: 30vh;
        font-size: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  div.C-container {
    main.C-mainPart {
      .C-div-part {
        .code-part {
          left: 2rem;
        }
        .execute-part {
          width: 70vw;
          height: 20vh;
          right: 2rem;
        }
      }
    }
  }
}

// border: 3px solid red;
@media screen and (max-width: 570px) {
  .odd {
    flex-direction: column;
    .code-part {
      p {
        padding: 0.5rem;
        padding-right: 0rem;
      }
    }
  }

  .even {
    flex-direction: column;
    .code-part {
      p {
        left: 2.5rem;
        padding: 0.5rem;
        padding-right: 0rem;
      }
    }
    .execute-part {
      left: -1.5rem;
    }
  }

  div.C-container {
    .title-container {
      margin:1.5rem;
    }
    .title-word {
      font-size: 2.5rem;
    }
    .title-description {
      font-size: 1.5rem;
      padding:10px;
      line-height: 2rem;
    }

    main.C-mainPart {
      .C-div-part {
        margin: 3rem;
        .code-part {
          left: 2.5rem;
          width: 60vw;
          p {
            font-size: 1.25rem;
          }
        }
        .execute-part {
          right: 1.5rem;
          padding: 0.5rem;
          padding-left: 0rem;
          line-height: 2rem;
          width: 90vw;
          height: 15vh;
          font-size: 1.25rem;
        }
      }
    }
  }
}
