.G-container {
  main.G-mainPart {
    display: flex;
    justify-content: center;
    div.imgContainer {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 1.5rem 2rem 1rem 2rem;
      img {
        width: 80%;
        height: 50vh;
        border-radius: 50%;
      }
      .G-desc {
        text-align: center;
        color: rgb(4, 108, 236);
        font-weight: bold;
        position: absolute;
        font-size: 2rem;
        padding-top: 5rem;
        width: 60%;
        border-radius: 50%;
      }
      .ga-1 {
        padding-top: 6rem;
      }

      p {
        position: relative;
        cursor: pointer;
        background-color: rgb(201, 225, 18, 0.5);
        border-radius: 20px;
        font-weight: bold;
        padding: 0.5rem;
        font-size: 1.75rem;
        margin-top: 1rem;
      }
      span {
        font-weight: bold;
        color: lightsalmon;
        animation: flashEffect 1s ease-in-out 0s infinite alternate;
        position: relative;
        top: -4rem;
        left: 4rem;
      }
    }
  }

  @keyframes flashEffect {
    from {
      transform: scale(1) rotate(10deg);
    }
    to {
      transform: scale(1.8) rotate(10deg);
    }
  }

  div.homeCare {
    padding: 5rem 0 3rem 0;
    text-align: center;
    button {
      cursor: pointer;
      padding: 0.5rem 0.75rem;
      font-size: 1.5rem;
      border: none;
      border-radius: 15px;
      background-color: lightskyblue;
      transition: all ease 0.3s;
      &:hover {
        background-color: rgb(39, 167, 246);
      }
    }

    main {
      .title-container {
        margin: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        .title-word {
          width: 100%;
          border-top: 5px solid rgba(128, 128, 128, 0.5);
          border-bottom: 5px solid rgba(128, 128, 128, 0.5);
        }
        .title-description {
          width: 90%;
        }
      }

      .homeCare-service {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        div {
          font-size: 2.25rem;
          width: 30%;
          padding: 1.5rem;
          margin: 1rem;
          border: 3px solid rgba(124, 104, 238, 0.8);
          border-radius: 40px;
          transition: all ease 0.8s;
          &:hover {
            background-color: rgba(124, 104, 238, 0.3);
            border: none;
            color: rgba(0, 0, 0, 0.6);
            font-weight: bold;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1200px) {
  .G-container {
    main.G-mainPart {
      div.imgContainer {
        margin: 1.5rem 2rem 1rem 2rem;
        img {
          width: 80%;
          height: 30vh;
        }
        .G-desc {
          font-size: 1.5rem;
          padding-top: 3rem;
          width: 70%;
        }
        .ga-1 {
          padding-top: 4rem;
        }
        p {
          cursor: pointer;
          background-color: rgb(201, 225, 18, 0.5);
          border-radius: 20px;
          font-weight: bold;
          padding: 0.5rem;
          font-size: 1.75rem;
          margin-top: 1rem;
        }
      }
    }

    div.homeCare {
      padding: 3rem 0 3rem 0;
      main {
        .title-container {
          .title-word {
            width: 100%;
            font-size: 2.5rem;
          }
          .title-description {
            width: 100%;
            font-size: 1.25rem;
          }
        }

        .homeCare-service {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          div {
            font-size: 1.5rem;
            width: 40%;
            padding: 1.5rem;
            margin: 0.75rem;
            &:hover {
              background-color: rgba(124, 104, 238, 0.3);
              border: none;
              color: rgba(0, 0, 0, 0.6);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .G-container {
    main.G-mainPart {
      flex-direction: column;
      div.imgContainer {
        img {
          width: 50%;
          height: 50vh;
        }
        .G-desc {
          font-size: 1.75rem;
          padding-top: 8rem;
          width: 40%;
        }
        .ga-1 {
          padding-top: 10rem;
        }
      }
    }
  }
}

@media screen and (max-width: 570px) {
  .G-container {
    .title-container {
      margin: 2rem;
    }
    .title-word {
      font-size: 2.5rem;
    }
    .title-description {
      font-size: 1.5rem;
      padding: 10px;
      line-height: 2rem;
    }

    main.G-mainPart {
      flex-direction: column;
      div.imgContainer {
        img {
          width: 80%;
          height: 35vh;
        }
        .G-desc {
          font-size: 1.5rem;
          padding-top: 5rem;
          width: 60%;
        }
        .ga-1 {
          padding-top: 6rem;
        }
      }
    }

    div.homeCare {
      padding: 3rem 0 3rem 0;
      main {
        .title-container {
          .title-word {
            width: 100%;
            font-size: 2.5rem;
          }
          .title-description {
            width: 100%;
            font-size: 1.25rem;
          }
        }

        .homeCare-service {
          display: flex;
          flex-wrap: wrap;
          flex-direction: row;
          justify-content: center;
          div {
            font-size: 1.5rem;
            width: 40%;
            padding: 1.5rem;
            margin: 0.75rem;
            &:hover {
              background-color: rgba(124, 104, 238, 0.3);
              border: none;
              color: rgba(0, 0, 0, 0.6);
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
